import * as React from 'react';
import { SiYourtraveldottv } from 'react-icons/si';
import { Box, Heading, List, ListIcon, ListItem, Text, chakra } from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

const ListContent: React.FC = ({ children }) => (
  <ListItem>
    <ListIcon as={SiYourtraveldottv} />
    {children}
  </ListItem>
);

const AboutShippingPage = () => {
  return (
    <Layout>
      <Seo
        title="送料と配送方法について"
        description="送料と配送方法について説明いたします。"
      />
      <JsonLd
        title="送料と配送方法について"
        description="送料と配送方法について説明いたします。"
      />
      <Box maxW={maxW} mx="auto">
        <Heading mt={16} mb={8} fontSize={{ base: '2xl', md: '5xl' }}>
          配送と支払い方法につきまして
        </Heading>
        <List mb={8} fontSize={{ base: 'md', md: '3xl' }} fontWeight="bold">
          <ListContent>配送につきまして</ListContent>
          <Box px={4}>
            <Text fontSize={{ base: 'md', md: '3xl' }} mb={4}>
              ・専用BOX付属(5,000円以上)商品→宅急便コンパクト（送料無料）
              <br />
              ・専用BOXなし(5,000円未満)商品→レターパックライト（送料全国一律370円）
            </Text>
            <Text fontSize={{ base: 'md', md: '3xl' }}>
              5,000円以上お買上げの場合
              <br />
              ・全て、宅急便コンパクト（送料無料）にてお送りします
            </Text>
          </Box>
        </List>
        <List mb={8} fontSize={{ base: 'md', md: '3xl' }} fontWeight="bold">
          <ListContent>お支払い方法につきまして</ListContent>
          <Box px={4}>
            <Text fontSize={{ base: 'md', md: '3xl' }} mb={4}>
              ・クレジットカード（VISA, MasterCard, JCB, AMEX）
              <br />
              ・コンビニ支払[前払い](ローソン、ファミリーマート、ミニストップ、デイリーヤマザキ)<br/>
              <chakra.span ml={{base: 4, md: 8}}>※決済手数料として190円が加算されます。</chakra.span>
              <br />
              ・AmazonPay
            </Text>
            <Text fontSize={{ base: 'md', md: '3xl' }}>
              ・お支払いから3営業日以内に発送します。
              <br />
              ・配送先情報に誤りがある場合は、配達にお時間を要する場合があります。
              <br />
              ・郵便番号など間違いのないようにご入力ください。
              <br />
              ・送先情報に問題のある場合、お客様に確認させていただくことがあります。
            </Text>
          </Box>
        </List>
        <List mb={8} fontSize={{ base: 'md', md: '3xl' }} fontWeight="bold">
          <ListContent>営業時間</ListContent>
          <Box px={4}>
            <Text fontSize={{ base: 'md', md: '3xl' }}>
              10:00-18:00(土日祝、年末年始・当社指定の休日を除く平日)
            </Text>
          </Box>
        </List>
      </Box>
    </Layout>
  );
};

export default AboutShippingPage;
